<template>
  <el-dialog title="设置置顶" :close-on-click-modal="false" :close-on-press-escape="false" :visible.sync="showTop"
    width="504px" :show-close="false" class="dialog-vertical">
    <div class="position">
      <span class="m-l-8" style="color:#67757c;margin-right: 20px;font-size: 16px">位置：</span>
      <el-radio v-model="radio" :label="3">全部分类页</el-radio>
      <el-radio v-model="radio" :label="2">单一分类页</el-radio>
    </div>
    <el-form label-width="70px" size="medium" class="filter-from medium-form" style="margin-top: 30px">
      <div class="filter-item">
        <!--        <DatePeriodPicker-->
        <!--          label="时间："-->
        <!--          start-prop="start_time"-->
        <!--          :start-time.sync="form.start_time"-->
        <!--          end-prop="end_time"-->
        <!--          :end-time.sync="form.end_time"-->
        <!--        />-->
        <el-form-item label="时间：" required>
          <!--          <DatePicker v-model="form.start_time" style="width: auto;" placeholder="请选择日期" type="datetime"-->
          <!--            format="yyyy-MM-dd HH:mm"></DatePicker>-->
          <!--          <span style="padding: 0 10px">至</span>-->
          <!--          <DatePicker v-model="form.end_time" style="width: auto;" placeholder="请选择日期" type="datetime"-->
          <!--            format="yyyy-MM-dd HH:mm"></DatePicker>-->
          <date-time-picker v-model="top"></date-time-picker>
          <p class="info"><i class="el-icon-info" />设置后，客户端置顶数据 5 分钟后生效</p>
        </el-form-item>
      </div>
    </el-form>
    <template #footer>
      <el-button size="small" @click="closeSet">取消</el-button>
      <el-button size="small" type="primary" @click="handleToSetTop" :loading="saveLoading">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
// import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
// import DatePicker from "@/base/components/Form/DatePicker";
import { setTopArticle } from "../../api/article-list";
import DateTimePicker from "../../../../base/components/Form/DateTimePicker";

export default {
  name: "SetTop",
  props: ["showTop", "select_id"],
  data() {
    return {
      radio: 2,
      top: ["", ""],
      // form: {
      //   start_time: -1,
      //   end_time: -1,
      // },
      // rules: {},
      saveLoading: false,
    };
  },
  methods: {
    closeSet() {
      this.top = ["", ""];
      this.$emit("closeSetTop");
    },
    handleToSetTop() {
      // if (this.form.start_time == "-1" || this.form.end_time == "-1") {
      if (this.top[0] == "-1" || this.top[1] == "-1") {
        this.$message({
          message: "请选择置顶时间",
          type: "warning",
        });
        return;
      }
      let data = {
        id: this.select_id,
        top_position: this.radio,
        // top_start_time: this.form.start_time,
        // top_end_time: this.form.end_time,
        top_start_time: this.top[0],
        top_end_time: this.top[1],
      };
      this.saveLoading = true;
      setTopArticle(data)
        .then(() => {
          // this.form = {
          //   start_time: -1,
          //   end_time: -1,
          // };
          this.$emit("refreshArticle");
          this.closeSet();
          this.saveLoading = false;
        })
        .catch(() => {
          this.saveLoading = false;
        });
    },
  },
  components: {
    DateTimePicker,
    // DatePicker,
  },
};
</script>

<style scoped lang="scss">
.position {
  display: flex;
  align-items: center;
  margin-left: 25px;
}

.dialog-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: center;
}

.dialog-vertical {
  ::v-deep.filter-from .filter-item .el-form-item__content input {
    width: 175px;
  }
}
</style>
