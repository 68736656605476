<template>
<div>

<el-dialog :z-index="1000" title="选择公众号" :visible.sync="innerVisible" width="800px" :show-close="true">
    <el-button style="margin-bottom: 20px;" @click="handleAuth" type="primary">授权公众号</el-button>
    <!-- 表格 -->
    <ListLayout
        :thead="thead"
        :on-fetch="getList"
        :filter-form="form"
    >
    <!-- 公众号名称 -->
    <template style="display:flex" slot="item_nameAvatar" slot-scope="{row}">
       <div style="display: flex;"><list-image :src="row.avatar" border-radius="50%"></list-image><span style="margin: 3px 0 0 5px">{{row.name}}</span></div>
    </template>
    <!-- 状态小圆点 -->
    <template slot="item_statusText" slot-scope="{row}">
       <list-status-text :type="row.status_text === '正常'? 'success': 'danger'" :text="row.status_text" />
    </template>
    <template v-slot:operation="scope">
      <!-- <el-button type="text" size="small" @click="handleImport()" v-if="scope.row.status === 1">选择导入</el-button> -->
      <el-button type="text" size="small" v-if="scope.row.status === 1">选择导入</el-button>
      <el-button type="text" size="small" @click="handleAuth(scope.row.id)" v-else>重新授权</el-button>
      <el-button type="text" size="small">移除</el-button>
    </template>
    </ListLayout>
  </el-dialog>
  <!-- 导入文章 -->
  <!-- <ImportArticle></ImportArticle> -->

  <!-- 授权公众号二维码弹窗 -->
  <el-dialog class="qrcode" @close="codeClose" v-loading="loading" title="授权公众号" :visible.sync="visibles" height="600px" width="800px" :show-close="true">
    <div class="code">
        <div class="text">微信公众号授权</div>
        <img :src="qrcodeurl || ''"  class="img" />
        <div class="text">请使用微信公众号绑定的管理员个人微信号扫描</div>
    </div>
    </el-dialog>
</div>

</template>

<script>
import {
  officialList,
  authQrcode,
  authStatus } from '../../api/article-popup'
import ListLayout from '@/base/layout/ListLayout'
import ListImage from "@/base/components/List/ListImage";
import ListStatusText from '@/base/components/List/ListStatusText'
// import ImportArticle from './ImportArticle'
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        // ImportArticle,
        ListLayout,
        ListImage,
        ListStatusText
    },
    data() {
        return {
            loading: false,
            list: [],
            innerVisible: this.visible,
            inner: false,
            visibles: false,
            thead: [
                { label: "序号", type: "index", minWidth: 100 },
                { label: "公众号名称", type: 'slot', prop: "nameAvatar", minWidth: 100 },
                { label: "状态", type: 'slot', prop: "statusText", minWidth: 100 },
                { label: "操作", type: "operation", minWidth: 50 },
            ],
            form: {
                page: 1,
                page_size: 15,
            },
            formlist: {
              id: '',
              page: 1,
              page_size: 15,
              type_id: null,
              category_id: null,
            },
            rules: {},
            category_option: [],
            // 类型
            type_option: [
              { label: "全部", value: -1 },
              { label: "纯文本", value: 0 },
              { label: "图文", value: 1 },
              { label: "大图", value: 2 },
              { label: "视频", value: 3 },
            ],
            // 二维码
            qrcode: '',
            qrcodeurl: '',
            // 二维授权状态
            qrstatus: -1,
            isAuthorization: false,
            close: false
        }
    },
    methods: {
        // 公众号列表数据
        getList() {
            return officialList()
        },
        // 授权公众号弹窗
        handleAuth() {
            this.visibles = true
            this.innerVisible = false
            this.close = false
            this.isAuthorization = true
            this.onRefreshCode()
        },

        // 导入文章
        // handleImport(id) {
        //     this.formlist.id = id
        //     this.inner = true
        //     this.innerVisible = false
        // }

        codeClose() {
          this.visibles = false
          this.innerVisible = true
          this.close = true
        },
        // 获取二维码
        async onRefreshCode() {
            this.loading = true;
             await authQrcode().then(res => {
                this.loading = false
                // 二维码
                this.qrcode =  res.data.auth_key
                this.qrcodeurl = res.data.url
            }).catch(err => this.loading = false)
            this.onRefreshStatus()
        },
        // 获取二维码授权的状态
        async onRefreshStatus() {
          // console.log('yyy', this.qrcode)
          await authStatus({ auth_key: this.qrcode })
            .then(res => {
            this.qrstatus = res.data.status
            this.isAuthorization = true
          })
        },
    },
    watch: {
        visible(val, oldVal) {
            if (val === oldVal) {
                return
            }
            this.innerVisible = val
        },
        // 如果内部有新值变化，更新外部visible
        innerVisible(val, oldVal) {
            if (val === oldVal) {
                return
            }
            this.$emit('update:visible', val)
        },
        // 轮询二维码状态
        isAuthorization() {
            let times = setInterval(async () => {
                // 二维码状态
                this.onRefreshStatus()
                // 未授权 -1
                if (this.qrstatus === -1) {}
                // 授权失败 0
                if (this.qrstatus === 0) {}
                // 授权成功 1
                if (this.qrstatus === 1) {
                    // 用户授权成功后清除定时器
                    this.$message.success('授权成功');
                    this.innerVisible = true
                    this.visibles = false
                    clearInterval(times)
                }
                // 关闭授权窗口清除定时器
                if (this.close) {
                  clearInterval(times)
                }
            }, 5000)
        }
    }
}
</script>

<style lang="scss" scope>
.qrcode {
    .code {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 300px;
        font-size: 18px;
        color: black;
        .img {
          width:140px;
          height: 140px;
        }
        .text {
          height: 80px;
          padding: 20px
        }
    }
}
</style>
