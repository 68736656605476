import api from "@/base/utils/request";

/**
 * 获取预览数据
 * @param{String} id          文章混淆id
 * @param{Number} isWrite    是否为写文章的预览，编辑时传0
 */
export const getPreviewData = (id, isWrite = 0) => {
  return api({
    url: '/admin/portal/article/preview',
    method: 'post',
    data: {
      id: id,
      is_write_preview: isWrite
    }
  })
}
