import { getPreviewData } from '@/modules/article-admin/api/base'

/**
 * 文章预览与同步
 */
export default {
  data() {
    return {
      // 预览弹窗
      preview: {
        show: false,
        data: null,
      },
      // 同步
      sync: {
        loading: false, // 正在获取要同步的内容时
      },
    }
  },
  methods: {
    /**
     * 新页面展示预览的文章内容
     * @param hash_id
     * @param is_write
     */
    toPreviewPage(hash_id, is_write = 'n') {
      let routerData = this.$router.resolve({
        name: 'ArticlePreview',
        params: {
          id: hash_id,
          w: is_write,
        },
      })
      window.open(routerData.href, '_blank')
    },
    /**
     * 弹窗形式展示预览的文章内容
     * @param hash_id
     * @param is_write_article
     */
    openPreviewDialog(hash_id, is_write_article = 'n') {
      this.preview.show = true // 打开弹窗
      if (is_write_article === 'y' || hash_id !== this.preview.data?.id) {
        this.getPreviewData(hash_id, null, is_write_article) // 设置弹窗内容
      }
    },
    /**
     * 获取文章被预览的内容
     * @param hash_id
     * @param callback
     * @param is_write_article
     */
    getPreviewData(hash_id, callback, is_write_article = 'n') {
      this.preview.data = {}
      getPreviewData(hash_id, is_write_article === 'n' ? 0 : 1)
        .then((res) => {
          this.preview.data = res.data
          callback && callback(res.data)
        })
        .catch((err) => {
          // if (this.$axios.isCancel(err)) return
        })
    },
    /**
     * 用户点击预览按钮
     * @param row
     * @param write
     */
    previewArticle(row, write = 'n') {
      if (row.article_type) {
        window.open(row.wx_url, '_blank') // 在外部打开公众号链接
      } else {
        // this.toPreviewPage(row.hash_id) // 方式一，跳转新页面
        this.openPreviewDialog(row.hash_id, write) // 方式二，在当前页面弹窗
      }
    },
    /**
     * 用户点击同步文章（到其他平台草稿箱）
     * @param hash_id
     * @param write
     */
    syncArticle(hash_id, write = 'n') {
      if (write === 'n') {
        if (this.preview.data?.id === hash_id) {
          this.windowSync(JSON.parse(JSON.stringify(this.preview.data)))
          return
        }
      }

      this.sync.loading = true
      let loading = this.$loading({ text: '' })

      this.getPreviewData(
        hash_id,
        (data) => {
          this.windowSync(data)
          this.sync.loading = false
          loading.close()
        },
        write
      )
    },
    /**
     * 使用了 wechatsync SDK 调用文章同步接口
     * @param data
     */
    windowSync(data) {
      const postInfo = {
        title: data.title,
        desc: data.summary || data.title,
        content: data.content,
        thumb:
          data.images &&
          data.images.length &&
          data.images[0] &&
          data.images[0].type === 'image' &&
          data.images[0].url
            ? data.images[0].url
            : '', // 封面，如果有
      }
      console.log('同步的文章信息：', postInfo)
      window.syncPost(postInfo)
    },
  },
}
