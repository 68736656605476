/*
* 批量导入公众号文章接口
*/
import api from "@/base/utils/request";

// 授权接口
// 已授权公众号文章
export const officialList = data => {
    return api({
        url: "/admin/portal/wx_public/publicList",
        method: "post",
        data
      });
}
// 授权二维码
export const authQrcode = data => {
    return api({
        url: "/admin/portal/wx_public/authQrcode",
        method: "post",
        data
      });
}
// 查询二维码授权状态
export const authStatus = data => {
    return api({
        url: "/admin/portal/wx_public/authStatus",
        method: "post",
        data
      });
}

// 导入文章接口
// 分类
// export const allClassifyData = data => {
//   return api({
//       url: "/admin/portal/category/all",
//       method: "post",
//       data
//   });
// };
// // 类型

// // 获取来源
// export const sourceList = data => {
//   return api({
//       url: "/admin/portal/source/index",
//       method: "post",
//       data
//   });
// };

// // 公众号文章列表
// export const publishList = data => {
//   return api({
//       url: "/admin/portal/wx_public/publishList",
//       method: "post",
//       data
//     });
// }

// // 文章导入
// export const addArticle = data => {
//   return api({
//       url: "/admin/portal/wx_public/addArticle",
//       method: "post",
//       data
//     });
// }
