var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"list-page"},[_c('ImportArticlePopup',{attrs:{"visible":_vm.showimport},on:{"update:visible":function($event){_vm.showimport=$event}}}),_c('ArticleScreen',{attrs:{"filterForm":_vm.articleForm,"uploadFilter":_vm.callbackForm}}),_c('list-tabs',{attrs:{"isLoading":_vm.show_loading,"tabs":_vm.tabArr},on:{"tab-click":_vm.handleChangeTab},model:{value:(_vm.articleForm.tab),callback:function ($$v) {_vm.$set(_vm.articleForm, "tab", $$v)},expression:"articleForm.tab"}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.show_loading),expression:"show_loading"}]},[_c('div',{staticClass:"article-list-top"},[(
            _vm.articleForm.tab !== 'recalled' && _vm.articleForm.tab !== 'deleted'
          )?_c('div',{staticStyle:{"display":"flex","margin-right":"10px"}},[(_vm.articleForm.tab === 'topped')?_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.cancelTop()}}},[_vm._v(" 取消置顶")]):_vm._e(),(_vm.articleForm.tab !== 'topped')?_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.handleSetTop(0)}}},[_vm._v(" 置顶 ")]):_vm._e()],1):_vm._e(),(
            _vm.articleForm.tab === 'recalled' || _vm.articleForm.tab === 'deleted'
          )?_c('el-button',{attrs:{"size":"small","type":"success"},on:{"click":function($event){return _vm.handlePutBack(0)}}},[_vm._v(" 还原 ")]):_vm._e(),_c('el-button',{attrs:{"size":"small","type":"danger"},on:{"click":function($event){return _vm.handleDelArticle(0)}}},[_vm._v("删除")]),(['college'].includes(_vm.tenantVersion))?_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.addThirdPartyCategory}},[_vm._v("新增标签")]):_vm._e(),(['college'].includes(_vm.tenantVersion))?_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.removeThirdPartyCategory}},[_vm._v("删除标签")]):_vm._e()],1),_c('el-table',{staticClass:"thead-light",staticStyle:{"width":"100%","margin-bottom":"50px","margin-top":"20px"},attrs:{"stripe":"","data":_vm.article_list},on:{"selection-change":_vm.handleSelection,"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_vm._l((_vm.tableData),function(item,index){return _c('el-table-column',{key:item.prop,attrs:{"show-overflow-tooltip":"","prop":item.prop,"label":item.label,"min-width":item.min_width ? item.min_width : '',"width":item.width,"sortable":item.sortable},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(item.prop === 'title')?[_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.previewArticle(scope.row)}}},[_vm._v(_vm._s(scope.row.title))])]:(item.prop === 'article_type')?[_c('span',[_vm._v(_vm._s(scope.row.article_type ? '跳转至公众号浏览' : '在小程序、H5 微网站内浏览'))])]:[_vm._v(_vm._s(_vm._f("placeholder")(scope.row[item.prop])))]]}}],null,true)})}),_c('el-table-column',{attrs:{"label":"操作","width":"250","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                _vm.articleForm.tab !== 'recalled' &&
                _vm.articleForm.tab !== 'deleted' &&
                scope.row.top_status < 1
              )?_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.handleSetTop(scope.row.id)}}},[_vm._v("置顶 ")]):_vm._e(),(
                _vm.articleForm.tab !== 'recalled' &&
                _vm.articleForm.tab !== 'deleted' &&
                scope.row.top_status === 1
              )?_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.cancelTop(scope.row.id)}}},[_vm._v("取消置顶 ")]):_vm._e(),(
                _vm.articleForm.tab !== 'recalled' &&
                _vm.articleForm.tab !== 'deleted'
              )?_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.toArticleDetail(
                  scope.row.id,
                  scope.row.portal_user_id,
                  scope.row
                )}}},[_vm._v("编辑 ")]):_vm._e(),(_vm.articleForm.tab === 'normal_no_recall')?_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.handleRecall(scope.row.id)}}},[_vm._v("撤回 ")]):_vm._e(),(_vm.articleForm.tab === 'recalled')?_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.handleRestore(scope.row.id)}}},[_vm._v("还原 ")]):_vm._e(),(_vm.articleForm.tab === 'deleted')?_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.handlePutBack(scope.row.id)}}},[_vm._v("还原 ")]):_vm._e(),_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.handleDelArticle(scope.row.id)}}},[_vm._v("删除 ")]),_c('MoreBtn',{attrs:{"trigger":"hover"},on:{"command":_vm.onMoreCommand}},[_c('el-dropdown-item',{attrs:{"command":{ type: 'updatePostTime', row: scope.row }}},[_vm._v("修改发布时间")]),(['college'].includes(_vm.tenantVersion))?_c('el-dropdown-item',{attrs:{"command":{ type: 'editThirdCategory', row: scope.row }}},[_vm._v("编辑标签")]):_vm._e(),(!scope.row.article_type && _vm.isShowSync)?_c('el-dropdown-item',{attrs:{"command":{ type: 'sync', row: scope.row }}},[_vm._v("同步该文章")]):_vm._e()],1)]}}])})],2),_c('Pagination',{attrs:{"total":_vm.pageData.total,"page":_vm.pageData.current_page,"change":_vm.changPage}})],1),_c('SetTop',{attrs:{"showTop":_vm.showTop,"select_id":_vm.select_id},on:{"closeSetTop":function($event){_vm.showTop = false},"refreshArticle":_vm.RefreshList}}),_c('article-viewer',{attrs:{"options":_vm.preview.data},model:{value:(_vm.preview.show),callback:function ($$v) {_vm.$set(_vm.preview, "show", $$v)},expression:"preview.show"}}),_c('AeleaseTimeDialog',{attrs:{"show":_vm.aeleaseTime.show},on:{"update:show":function($event){return _vm.$set(_vm.aeleaseTime, "show", $event)},"confirm":_vm.updatePostTime}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }