<template>
  <div>
    <el-dialog
      width="500px"
      :visible="myShow"
      title="发布时间修改"
      @close="close"
      append-to-body
      destroy-on-close
    >
            
      <el-form size="medium"  label-position="right" @submit.native.prevent  :label-width="'105px'">
        <el-form-item label="选择时间：">
          <el-date-picker
            v-model="value"
            type="datetime"
            value-format="timestamp"
            placeholder="选择日期时间"
            :picker-options="pickerOptions"
            format="yyyy-MM-dd HH:mm"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" @click="close">取消</el-button>
        <el-button size="small" type="primary" @click="confirm">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      value: '',
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() >  new Date().getTime()
        },
      },
    }
  },
  computed: {
    myShow: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      },
    },
  },
  methods: {
    confirm() {
      if (this.value) {
        this.$emit('confirm', (this.value / 1000).toFixed(0))
        this.isModify = true
        this.close()
      } else {
        this.$message.error('请选择时间')
      }
    },
    close() {
      this.myShow = false
      this.value = ''
    },
  },
}
</script>

<style scoped></style>
