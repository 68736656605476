<template>
  <el-dialog :visible="value" class="article-viewer dialog-vertical" close-on-click-modal
             width="380px" :show-close="false" @close="close">
    <Phone>
      <div class="page-content" v-loading="!myOptions || !myOptions.content">
        <div class="top-content" v-if="myOptions && myOptions.title">
          <div class="title">{{myOptions.title}}</div>
          <div class="info" v-if="myOptions.source_name || (myOptions.source && myOptions.source.name)">
            <span class="source-name">{{myOptions.source_name || (myOptions.source && myOptions.source.name)}}</span>
            <span class="divider">发布于</span>
            <span class="publish-time" v-if="myOptions.post_time">{{postTime}}</span>
<!--            <span class="publish-time" v-if="myOptions.post_time">{{myOptions.post_time}}</span>-->
            <span class="publish-time" v-else>刚刚</span>
          </div>
        </div>
        <div class="content" v-html="myContent"></div>
      </div>
    </Phone>
  </el-dialog>
</template>

<script>
  import Phone from "./Phone";
  import dayjs from 'dayjs'

  export default {
    name: "ArticleViewer",
    components: {Phone},
    props: {
      // 显示隐藏弹窗
      value: {
        type: Boolean,
        required: true
      },
      options: {
        type: Object,
        default: function () {
          return {}
        }
      }
    },
    data() {
      return {
        myOptions: {}
      }
    },
    watch: {
      value(val) {
        if (val) {
          this.myOptions = this.options
        } else {
          this.myOptions = {};
        }
      },
      options: {
        handler(val) {
          this.myOptions = val;
        },
        deep: true
      }
    },
    computed: {
      myContent: function () {
        if (!this.myOptions || !this.myOptions.content) return ''
        return this.myOptions.content
          .replace(/\\n/gi, '')
          .replace(/<\/section>\s+<section/gi, '</section><section')
          .replace(/><\/section>/gi, '><br/></section>')
          .replace(/<img/gi, '<img class="max-width"')
          .replace(/<video/gi, '<video class="max-width"')
          // .replace(/<section/gi, '<section class="overflow-x"')
          // .replace(/<p/gi, '<p class="overflow-x"')
      },
      postTime() {
        return dayjs(this.myOptions.post_time).format('MM月DD日 HH:mm')
      }
    },
    created() {
      this.myOptions = this.options;
    },
    methods: {
      close() {
        this.$emit('input', false)
      }
    }
  }
</script>

<style lang="scss">
  .article-viewer {
    /* Chrome */
    &::v-deep.el-dialog {
      background: unset;
      box-shadow: unset;

      .el-dialog__header,.el-dialog__body{
        padding: 0;
      }
    }

    /* FireFox */
    .el-dialog {
      background: unset;
      box-shadow: unset;

      .el-dialog__header,.el-dialog__body{
        padding: 0;
      }
    }

    .page-content {
      /*padding-left: 14px;*/
      min-height: 92%;

      .top-content, .content {
        padding: 0 12px;
      }

      .top-content {
        padding-bottom: 20px;
        box-shadow: 0 1px 4px 0 #f2f2f2;

        .title {
          font-size: 20px;
          font-weight: bold;
          line-height: 28px;
          color: #191e29;
          text-align: justify;
        }

        .info {
          margin-top: 16px;
          font-size: 12px;
          color: #8c8e91;
          line-height: 12px;

          .divider {
            margin: 0 9px;
          }
        }
      }

      .summary {

      }

      .content {
        margin-top: 25px;
        padding-bottom: 50px;
      }
    }
    .max-width {
      width: 100% !important;
      height: auto !important;
    }
  }
</style>

<style scoped>
  >>>.rich_media_content * {
    max-width: 100% !important;
    box-sizing: border-box !important;
    word-wrap: break-word !important;
  }

  >>>.rich_media_content {
    padding: 0;
    margin: 0;
    /*font-size:0;*/
    font-size: 17px;
  }

  >>>.autoTypeSetting24psection>section,
  >>>.autoTypeSetting24psection>p {
    margin-top: 0;
    margin-bottom: 24px;
  }

  >>>.rich_media_content .h5_image_link {
    line-height: 0;
    font-size: 0;
    display: inline-block;
    position: relative;
    vertical-align: bottom;
    user-select: none;
  }

</style>
