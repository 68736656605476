<!--
 * @Author: mulingyuer
 * @Date: 2021-04-13 10:21:26
 * @LastEditTime: 2021-04-14 14:25:51
 * @LastEditors: mulingyuer
 * @Description: 日期时间组件
 * @FilePath: \saas-admin-vue\src\base\components\Form\DateTimePicker.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-date-picker class="my-date-time" v-model="dateVal" type="datetimerange" :range-separator="attach"
                  format="yyyy-MM-dd HH:mm"
                  :start-placeholder="startPlaceholder" :end-placeholder="endPlaceholder" time-arrow-control>
  </el-date-picker>
</template>
<script>
export default {
  model: {
    prop: "date",
    event: "handleDate",
  },
  props: {
    //绑定的数据
    date: {
      type: Array,
      required: true,
    },
    //开始时间文字占位符
    startPlaceholder: {
      default: "开始日期",
    },
    //结束时间文字占位符
    endPlaceholder: {
      default: "结束日期",
    },
    //连接符
    attach: {
      default: "至",
    },
  },
  computed: {
    dateVal: {
      get() {
        let timeArr = ["", ""];
        if (this.date.length === 2) {
          let start = this.date[0];
          start = start !== "" && start >= 0 ? start * 1000 : "";
          let end = this.date[1];
          end = end !== "" && end >= 0 ? end * 1000 : "";
          timeArr = [start, end];
        }
        return timeArr;
      },
      set(val) {
        let timeArr = [];
        if (val) {
          val.forEach((item) => {
            if (item) {
              timeArr.push(Math.trunc(item / 1000));
            } else {
              timeArr.push(-1);
            }
          });
        } else {
          timeArr = [-1, -1];
        }
        this.$emit("handleDate", timeArr);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.my-date-time {
  max-width: 100%;

  ::v-deep .el-range-separator {
    width: auto;
  }
}
</style>
