import api from "@/base/utils/request";

// 获取所有分类
export const getAllCategories = data => {
  return api({
    url: '/admin/portal/category/all',
    method: 'post',
    data
  })
}

// 获取所有来源
export const getAllSources = data => {
  return api({
    url: "/admin/portal/source/all",
    method: "post",
    data
  });
};

// 获取公众号文章标题
export const getWxArticleTitle = data => {
  return api({
    url: '/admin/portal/article/getWxArticleTitle',
    method: 'post',
    data
  })
}

