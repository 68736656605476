import api from "@/base/utils/request";

// 获取分类列表
export const classifyList = data => {
    return api({
        url: "/admin/portal/category/index",
        method: "post",
        data
    });
};
//新增分类
export const addClassify = data => {
    return api({
        url: "/admin/portal/category/save",
        method: "post",
        data
    });
};
//修改显示状态
export const updateStatus = data => {
    return api({
        url: "/admin/portal/category/updateStatus",
        method: "post",
        data
    });
};
//删除分类
export const deleteClassify = data => {
    return api({
        url: "/admin/portal/category/del",
        method: "post",
        data
    });
};
//转移分类
export const moveClassify = data => {
    return api({
        url: "/admin/portal/category/move",
        method: "post",
        data
    });
};
//获取一级分类数据
export const classifyData = data => {
    return api({
        url: "/admin/portal/category/allParents",
        method: "post",
        data
    });
};
//获取所有分类数据
export const allClassifyData = data => {
    return api({
        url: "/admin/portal/category/all",
        method: "post",
        data
    });
};
//排序
export const updateSort = data => {
    return api({
        url: "/admin/portal/category/updateSort",
        method: "post",
        data
    });
};
//作者
export const getAuthorsList = data => {
    return api({
        url: "/admin/portal/article/authorsList",
        method: "post",
        data,
    });
};
