<template>
  <div class="page">
    <div class="list-page">
      <!--      <el-button size="small" @click="handleDocumentArticle" type="primary">导出文章</el-button>-->
      <!--       <el-button style="margin-bottom: 20px;" @click="handlePopup" type="primary">批量导入公众号文章</el-button>-->
      <!-- 批量导入公众号文章弹窗 -->
      <ImportArticlePopup :visible.sync="showimport"></ImportArticlePopup>

      <ArticleScreen
        :filterForm="articleForm"
        :uploadFilter="callbackForm"
      ></ArticleScreen>
      <list-tabs
        v-model="articleForm.tab"
        :isLoading="show_loading"
        :tabs="tabArr"
        @tab-click="handleChangeTab"
      >
      </list-tabs>
      <div v-loading="show_loading">
        <div class="article-list-top">
          <div
            style="display: flex; margin-right: 10px"
            v-if="
              articleForm.tab !== 'recalled' && articleForm.tab !== 'deleted'
            "
          >
            <!--                <el-button size="small">导出文章</el-button>-->
            <el-button
              size="small"
              type="primary"
              v-if="articleForm.tab === 'topped'"
              @click="cancelTop()"
            >
              取消置顶</el-button
            >
            <el-button
              size="small"
              type="primary"
              v-if="articleForm.tab !== 'topped'"
              @click="handleSetTop(0)"
            >
              置顶
            </el-button>
            <!--                <el-button type="warning">推荐</el-button>-->
            <!--                <el-button size="small" type="primary">加入专题</el-button>-->
          </div>
          <el-button
            size="small"
            type="success"
            v-if="
              articleForm.tab === 'recalled' || articleForm.tab === 'deleted'
            "
            @click="handlePutBack(0)"
          >
            还原
          </el-button>
          <el-button size="small" type="danger" @click="handleDelArticle(0)"
            >删除</el-button
          >
          <el-button
            v-if="['college'].includes(tenantVersion)"
            size="small"
            type="primary"
            @click="addThirdPartyCategory"
            >新增标签</el-button
          >
          <el-button
            v-if="['college'].includes(tenantVersion)"
            size="small"
            type="primary"
            @click="removeThirdPartyCategory"
            >删除标签</el-button
          >
        </div>
        <el-table
          style="width: 100%; margin-bottom: 50px; margin-top: 20px"
          class="thead-light"
          stripe
          :data="article_list"
          @selection-change="handleSelection"
          @sort-change="sortChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column
            v-for="(item, index) in tableData"
            :key="item.prop"
            show-overflow-tooltip
            :prop="item.prop"
            :label="item.label"
            :min-width="item.min_width ? item.min_width : ''"
            :width="item.width"
            :sortable="item.sortable"
          >
            <template slot-scope="scope">
              <template v-if="item.prop === 'title'">
                <span
                  @click="previewArticle(scope.row)"
                  style="cursor: pointer"
                  >{{ scope.row.title }}</span
                >
              </template>
              <!-- 判断是否是公众号文章 -->
              <template v-else-if="item.prop === 'article_type'">
                <span>{{
                  scope.row.article_type
                    ? '跳转至公众号浏览'
                    : '在小程序、H5 微网站内浏览'
                }}</span>
              </template>
              <template v-else>{{
                scope.row[item.prop] | placeholder
              }}</template>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="250" fixed="right">
            <template slot-scope="scope">
              <el-button
                size="small"
                type="text"
                v-if="
                  articleForm.tab !== 'recalled' &&
                  articleForm.tab !== 'deleted' &&
                  scope.row.top_status < 1
                "
                @click="handleSetTop(scope.row.id)"
                >置顶
              </el-button>
              <el-button
                size="small"
                type="text"
                v-if="
                  articleForm.tab !== 'recalled' &&
                  articleForm.tab !== 'deleted' &&
                  scope.row.top_status === 1
                "
                @click="cancelTop(scope.row.id)"
                >取消置顶
              </el-button>
              <el-button
                size="small"
                type="text"
                v-if="
                  articleForm.tab !== 'recalled' &&
                  articleForm.tab !== 'deleted'
                "
                @click="
                  toArticleDetail(
                    scope.row.id,
                    scope.row.portal_user_id,
                    scope.row
                  )
                "
                >编辑
              </el-button>
              <el-button
                size="small"
                type="text"
                v-if="articleForm.tab === 'normal_no_recall'"
                @click="handleRecall(scope.row.id)"
                >撤回
              </el-button>
              <el-button
                size="small"
                type="text"
                v-if="articleForm.tab === 'recalled'"
                @click="handleRestore(scope.row.id)"
                >还原
              </el-button>
              <el-button
                size="small"
                type="text"
                v-if="articleForm.tab === 'deleted'"
                @click="handlePutBack(scope.row.id)"
                >还原
              </el-button>
              <el-button
                size="small"
                type="text"
                @click="handleDelArticle(scope.row.id)"
                >删除
              </el-button>
              <MoreBtn trigger="hover" @command="onMoreCommand">
                <el-dropdown-item
                  :command="{ type: 'updatePostTime', row: scope.row }"
                  >修改发布时间</el-dropdown-item
                >
                <el-dropdown-item
                  v-if="['college'].includes(tenantVersion)"
                  :command="{ type: 'editThirdCategory', row: scope.row }"
                  >编辑标签</el-dropdown-item
                >
                <el-dropdown-item
                  v-if="!scope.row.article_type && isShowSync"
                  :command="{ type: 'sync', row: scope.row }"
                  >同步该文章</el-dropdown-item
                >
              </MoreBtn>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="pageData.total"
          :page="pageData.current_page"
          :change="changPage"
        ></Pagination>
      </div>

      <SetTop
        :showTop="showTop"
        :select_id="select_id"
        @closeSetTop="showTop = false"
        @refreshArticle="RefreshList"
      ></SetTop>
      <article-viewer
        v-model="preview.show"
        :options="preview.data"
      ></article-viewer>
      <AeleaseTimeDialog
        :show.sync="aeleaseTime.show"
        @confirm="updatePostTime"
      />
    </div>
  </div>
</template>

<script>
import '/public/article-syncjs-0.0.1/dist/styles.css'
// import '../util/article-syncjs-0.0.1/dist/styles.css'
// import '../util/article-syncjs-0.0.1/dist/main'

import { getPreviewData } from '../api/base'
import {
  articleList,
  softDeleteArticle,
  deleteArticle,
  putBackArticle,
  cancelTopArticle,
  recallArticle,
  restoreArticle,
  documentArticle,
  sevaUpdatePostTime,
  updateThirdCategory,
} from '../api/article-list.js'
import Pagination from '@/base/components/Default/Pagination'
import SetTop from '../components/ArticleList/SetTop'
import ArticleScreen from '../components/ArticleList/ArticleScreen'
import { aElementDownload } from '@/base/utils/tool'
import ArticleViewer from '@/base/components/Preview/ArticleViewer'
import ImportArticlePopup from '../components/ArticleList/ImportArticlePopup'
import AeleaseTimeDialog from '../components/ArticleList/AeleaseTimeDialog'
import MoreBtn from '@/base/components/Table/MoreBtn'
import previewArticle from '../mixins/previewArticle'
import query from '../../../base/mixins/query'

export default {
  name: 'article-list',
  components: {
    Pagination,
    SetTop,
    ArticleScreen,
    ArticleViewer,
    ImportArticlePopup,
    AeleaseTimeDialog,
    MoreBtn,
  },
  mixins: [previewArticle, query],
  data() {
    return {
      articleForm: {
        page_size: 15,
        tab: 'normal_no_recall',
        category_id: 0,
        type: -1,
        start_time: -1,
        end_time: -1,
        keyword: '',
        top_position: -1,
        top_status: -1,
        order_by: 'post_time',
        is_desc: 1,
        author_name: '',
        article_type: -1,
        source_id: -1,
      },
      pageData: {},
      // 筛选回调
      filters: {
        normal_no_recall: null,
        published: null,
        topped: null,
        recalled: null,
        deleted: null,
      },
      tableData: [
        { label: 'ID', prop: 'id', width: 80 },
        { label: '标题', prop: 'title', min_width: 330 },
        { label: '来源', prop: 'source_name', width: 110 },
        { label: '分类', prop: 'category_name', width: 110 },
        { label: '类型', prop: 'article_type', min_width: 200 },
        { label: '作者', prop: 'author_name', width: 110 },
        { label: '状态', prop: 'status_text', width: 110 },
        { label: '评论', prop: 'comments_count', width: 50 },
        // { label: "点赞", prop: "likes_count", width: 50 },
        { label: '收藏', prop: 'collects_count', width: 50 },
        { label: '浏览', prop: 'read_count', width: 50 },
        { label: '发布时间', prop: 'post_time', width: 200, sortable: true },
      ],
      //tab栏
      tabArr: [
        {
          name: 'normal_no_recall',
          label: '全部',
          total: 0,
        },
        {
          name: 'published',
          label: '已发布',
          total: 0,
        },
        {
          name: 'topped',
          label: '置顶',
          total: 0,
        },
        {
          name: 'recalled',
          label: '撤回',
          total: 0,
        },
        {
          name: 'deleted',
          label: '回收站',
          total: 0,
        },
      ],
      //文章列表
      article_list: [],
      // 弹窗
      showimport: false,
      //加载动画
      show_loading: false,
      //选中项
      select_id: [],
      //设置置顶
      showTop: false,
      preview: {
        show: false,
        data: {},
      },
      aeleaseTime: {
        show: false,
        id: '',
      },
      isShowSync: process.env.VUE_APP_MODE === 'development',
    }
  },
  computed: {
    filterForm: {
      get() {
        return this.articleForm
      },
      set(val) {
        this.articleForm = val
      },
    },
    // 后台版本
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
  },
  watch: {
    'articleForm.tab': {
      handler() {
        if (this.articleForm.tab === 'topped') {
          this.tableData = [
            { label: 'ID', prop: 'id', width: 80 },
            { label: '标题', prop: 'title', min_width: 150 },
            { label: '位置', prop: 'top_position_text', width: 120 },
            { label: '置顶状态', prop: 'top_status_text', width: 110 },
            {
              label: '开始时间',
              prop: 'top_start_time',
              width: 150,
              sortable: true,
            },
            {
              label: '结束时间',
              prop: 'top_end_time',
              width: 150,
              sortable: true,
            },
          ]
        } else if (['college'].includes(this.tenantVersion)) {
          this.tableData = [
            { label: 'ID', prop: 'id', width: 80 },
            { label: '标题', prop: 'title', min_width: 330 },
            { label: '来源', prop: 'source_name', width: 110 },
            { label: '标签', prop: 'third_party_category', width: 110 },
            { label: '分类', prop: 'category_name', width: 110 },
            { label: '类型', prop: 'article_type', min_width: 200 },
            { label: '作者', prop: 'author_name', width: 110 },
            { label: '状态', prop: 'status_text', width: 110 },
            { label: '评论', prop: 'comments_count', width: 50 },
            { label: '点赞', prop: 'likes_count', width: 50 },
            { label: '收藏', prop: 'collects_count', width: 50 },
            { label: '浏览', prop: 'read_count', width: 50 },
            {
              label: '发布时间',
              prop: 'post_time',
              width: 200,
              sortable: true,
            },
          ]
        } else {
          this.tableData = [
            { label: 'ID', prop: 'id', width: 80 },
            { label: '标题', prop: 'title', min_width: 330 },
            { label: '来源', prop: 'source_name', width: 110 },
            { label: '分类', prop: 'category_name', width: 110 },
            { label: '类型', prop: 'article_type', min_width: 200 },
            { label: '作者', prop: 'author_name', width: 110 },
            { label: '状态', prop: 'status_text', width: 110 },
            { label: '评论', prop: 'comments_count', width: 50 },
            { label: '点赞', prop: 'likes_count', width: 50 },
            { label: '收藏', prop: 'collects_count', width: 50 },
            { label: '浏览', prop: 'read_count', width: 50 },
            {
              label: '发布时间',
              prop: 'post_time',
              width: 200,
              sortable: true,
            },
          ]
        }
      },
      immediate: true,
    },
  },
  created() {
    this.show_loading = true
    // this.getArticleList(this.articleForm)
  },
  methods: {
    // 新增标签
    addThirdPartyCategory() {
      if (!this.select_id.length) {
        this.$message({
          type: 'info',
          message: '请先选择数据!',
        })
        return
      }
      this.$prompt('请输入要新增标签的名字', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '多个标签用,分隔：标签1,标签2',
        inputValidator: (value) => {
          if (!value) {
            return '请输入内容'
          }
        },
      })
        .then(({ value }) => {
          if (value) {
            let hashIds = []
            console.log(this.article_list)
            // 拿到哈希id
            this.article_list.forEach((item) => {
              console.log(this.select_id.includes(item.id), item.id)
              if (this.select_id.includes(item.id)) {
                hashIds.push(item.hash_id)
              }
            })
            updateThirdCategory({
              id: hashIds,
              update_type: 0,
              third_party_category: value,
            }).then((res) => {
              console.log(res)
              this.$message({
                type: 'success',
                message: res.msg,
              })
              this.RefreshList()
            })
          }
        })
        .catch(() => {})
    },
    // 移除标签
    removeThirdPartyCategory() {
      if (!this.select_id.length) {
        this.$message({
          type: 'info',
          message: '请先选择数据!',
        })
        return
      }
      this.$prompt('请输入要删除标签的名字', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '多个标签用,分隔：标签1,标签2',
        inputValidator: (value) => {
          if (!value) {
            return '请输入内容'
          }
        },
      })
        .then(({ value }) => {
          if (value) {
            let hashIds = []
            // 拿到哈希id
            this.article_list.forEach((item) => {
              if (this.select_id.includes(item.id)) {
                hashIds.push(item.hash_id)
              }
            })
            updateThirdCategory({
              id: hashIds,
              update_type: 1,
              third_party_category: value,
            }).then((res) => {
              console.log(res)
              this.$message({
                type: 'success',
                message: res.msg,
              })
              this.RefreshList()
            })
          }
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '取消输入',
          // })
        })
    },
    getList(q) {
      this.getArticleList(q || this.articleForm)
    },
    //更多按钮点击事件
    onMoreCommand({ type, row }) {
      switch (type) {
        case 'updatePostTime': //查看报名信息
          this.aeleaseTime.show = true
          this.aeleaseTime.id = row.id
          break
        case 'editThirdCategory': //更换文章标签
          this.$prompt('请输入要更换标签的名字', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputPlaceholder: '多个标签用,分隔：标签1,标签2',
            inputValue: row.third_party_category,
            inputValidator: (value) => {
              if (!value) {
                return '请输入内容'
              }
            },
          })
            .then(({ value }) => {
              if (value) {
                updateThirdCategory({
                  id: row.hash_id,
                  update_type: 2,
                  third_party_category: value,
                }).then((res) => {
                  console.log(res)
                  this.$message({
                    type: 'success',
                    message: res.msg,
                  })
                  this.RefreshList()
                })
              }
            })
            .catch(() => {})
          break
        case 'sync':
          this.syncArticle(row.hash_id)
          break
      }
    },
    updatePostTime(post_time) {
      sevaUpdatePostTime({ id: this.aeleaseTime.id, post_time }).then((res) => {
        this.$message({
          message: '修改成功',
          type: 'success',
        })
        this.RefreshList()
      })
    },
    handleDocumentArticle() {
      documentArticle(this.articleForm)
        .then((res) => {
          console.log(res)
          aElementDownload(res, '文章列表.xlsx')
        })
        .catch((err) => {
          console.log(err)
        })
    },
    sortChange(e) {
      this.articleForm.order_by = e.prop
      if (e.order == 'ascending') {
        this.articleForm.is_desc = 0
      } else if (e.order == 'descending') {
        this.articleForm.is_desc = 1
      }
      this.RefreshList()
    },
    toArticleDetail(id, portal_user_id, row) {
      if (row.article_type) {
        this.$router.push({
          path: '/admin/article-admin/article/edit-wx-article',
          query: {
            id: id,
            portal_user_id: portal_user_id,
            come_from: 'ArticleList',
          },
        })
      } else {
        this.$router.push({
          path: '/admin/article-admin/article/edit-article',
          query: {
            id: id,
            portal_user_id: portal_user_id,
            come_from: 'ArticleList',
          },
        })
      }
    },
    callbackForm(e) {
      this.articleForm = e
      this.getArticleList(this.articleForm)
    },
    //文章撤回
    handleRecall(id) {
      this.show_loading = true
      recallArticle({ id: id })
        .then((res) => {
          this.$message({
            message: '撤回成功',
            type: 'success',
          })
          this.RefreshList()
        })
        .catch(() => {})
        .finally(() => {
          this.show_loading = false
        })
    },
    //撤回还原
    handleRestore(id) {
      this.show_loading = true
      restoreArticle({ id: id })
        .then((res) => {
          this.$message({
            message: '还原成功',
            type: 'success',
          })
          this.RefreshList()
        })
        .catch(() => {})
        .finally(() => {
          this.show_loading = false
        })
    },
    //删除还原
    handlePutBack(id) {
      if (id == 0 && this.select_id.length == 0) {
        this.$message({
          message: '请选择你要还原的文章',
          type: 'info',
        })
        return
      }
      if (id > 0) {
        this.select_id = id
      }
      this.show_loading = true
      putBackArticle({ id: this.select_id })
        .then((res) => {
          this.$message({
            message: '还原成功',
            type: 'success',
          })
          this.RefreshList()
        })
        .catch(() => {})
        .finally(() => {
          this.show_loading = false
        })
    },
    // 取消置顶
    cancelTop(id) {
      if (!(id > 0) && this.select_id.length == 0) {
        this.$message({
          message: '请选择你要取消置顶的文章',
          type: 'info',
        })
        return
      }
      if (id > 0) {
        this.select_id = id
      }
      this.show_loading = true
      cancelTopArticle(this.select_id)
        .then((res) => {
          this.$message.success(res.msg)
          this.RefreshList()
        })
        .catch(() => {})
        .finally(() => {
          this.show_loading = false
        })
    },
    //删除
    handleDelArticle(id) {
      if (id == 0 && this.select_id.length == 0) {
        this.$message({
          message: '请选择你要删除的文章',
          type: 'info',
        })
        return
      }
      if (id > 0) {
        this.select_id = id
      }
      let title = ''
      if (this.articleForm.tab === 'deleted') {
        title = '此操作将永久删除文章, 是否继续?'
      } else {
        title = '此操作将删除该文章, 是否继续?'
      }
      this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: this.articleForm.tab === 'deleted' ? 'danger' : 'info',
      })
        .then(() => {
          this.show_loading = true
          if (this.articleForm.tab === 'deleted') {
            deleteArticle({ id: this.select_id })
              .then((res) => {
                this.RefreshList()
                this.$message({
                  type: 'success',
                  message: '删除成功!',
                })
              })
              .finally(() => {
                this.show_loading = false
              })
          } else {
            softDeleteArticle({ id: this.select_id })
              .then((res) => {
                this.RefreshList()
                this.$message({
                  type: 'success',
                  message: '删除成功!',
                })
              })
              .finally(() => {
                this.show_loading = false
              })
          }
        })
        .catch((err) => {})
    },
    //tab栏切换
    handleChangeTab(e) {
      if (!this.filters[e.name]) {
        this.articleForm = {
          page_size: this.pageData.per_page,
          tab: e.name,
          category_id: 0,
          type: -1,
          start_time: -1,
          end_time: -1,
          keyword: '',
          top_position: -1,
          top_status: -1,
          order_by: 'post_time',
          is_desc: 1,
          author_name: '',
        }
      } else {
        this.articleForm = this.filters[e.name]
      }
      this.getArticleList(this.articleForm)
    },
    //选中项
    handleSelection(data) {
      let arr = []
      data.forEach((item, index) => {
        arr.push(item.id)
      })
      this.select_id = arr
    },
    //页面切换
    changPage(data) {
      const pageData = { ...this.articleForm, ...data }
      this.articleForm.page_size = data.page_size
      this.getArticleList(pageData)
    },
    //设置置顶
    handleSetTop(id) {
      if (id == 0 && this.select_id.length == 0) {
        this.$message({
          message: '请选择你要置顶的文章',
          type: 'info',
        })
        return
      }
      if (id > 0) {
        this.select_id = id
      }
      this.showTop = true
    },
    //获取文章列表
    getArticleList(requestData) {
      this.article_list = []
      this.pageData = {}
      this.show_loading = true
      articleList(requestData)
        .then((res) => {
          this.article_list = res.data.data
          this.pageData = res.data

          const tab = this.tabArr.find((t) => t.name === requestData.tab)
          tab.total = res.data.total

          this.filters[res.data.tab] = {
            ...this.articleForm,
            page_size: res.data.per_page,
          }
          this.show_loading = false
        })
        .catch((err) => {
          this.show_loading = false
        })
    },
    // 刷新文章列表
    RefreshList() {
      this.getArticleList({
        ...this.articleForm,
        page: this.pageData.current_page,
      })
    },
    // 批量导入公众号文章
    handlePopup() {
      this.showimport = true
    },
  },
}
</script>

<style scoped lang="scss">
.article-list-top {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
</style>
