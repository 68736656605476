import api from "@/base/utils/request";
import fetchFile from "@/base/utils/download";
import download from "@/base/utils/download";

//获取顶部栏
export const articleTab = data => {
  return api({
    url: "/admin/portal/article/tabs",
    method: "post",
    data
  });
};

// 获取文章列表
export const articleList = data => {
  return api({
    url: "/admin/portal/article/index",
    method: "post",
    data
  });
};

// 软删除
export const softDeleteArticle = data => {
  return api({
    url: "/admin/portal/article/softDelete",
    method: "post",
    data
  });
};

// 硬删除
export const deleteArticle = data => {
  return api({
    url: "/admin/portal/article/del",
    method: "post",
    data
  });
};

// 还原
export const putBackArticle = data => {
  return api({
    url: "/admin/portal/article/putBack",
    method: "post",
    data
  });
};

// 文章置顶
export const setTopArticle = data => {
  return api({
    url: "/admin/portal/article/top",
    method: "post",
    data
  });
};

/**
 * 文章取消置顶
 * @param{Number} id
 */
export const cancelTopArticle = (id) => {
  return api({
    url: '/admin/portal/article/cancelTop',
    method: 'post',
    data: {
      id
    }
  })
}

//文章撤回
export const recallArticle = data => {
  return api({
    url: "/admin/portal/article/recall",
    method: "post",
    data
  });
};

//撤回还原
export const restoreArticle = data => {
  return api({
    url: "/admin/portal/article/restore",
    method: "post",
    data
  });
};
//文章导出
export const documentArticle = data => {
  return fetchFile({
    url: "/admin/portal/article/document",
    method: "post",
    data,
    download: true
  });
};

// 订单导出
export const orderDocument = data => {
  return download({
    url: "/admin/portal/article/document",
    method: "post",
    data,
  });
};
//作者
export const getAuthorsList = data => {
  return api({
    url: "/admin/portal/article/authorsList",
    method: "post",
    data,
  });
};
export const sevaUpdatePostTime = data=> {
  return api({
    url: "/admin/portal/article/updatePostTime",
    method: "post",
    data,
  });
}
export const updateThirdCategory = data=> {
  return api({
    url: "/admin/portal/article/updateThirdCategory",
    method: "post",
    data,
  });
}
